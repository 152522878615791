export const Label = {
  ABORT_BUTTON: "Abbrechen",
  PLACEHOLDER: "Wonach suchst du?",

  TRENDING_QUERY_HEADER: "BELIEBTE SUCHEN",

  HISTORY_HEADER: "DEINE SUCHHISTORIE",
  HISTORY_EDIT_BUTTON: "Bearbeiten",
  HISTORY_ABORT_BUTTON: "Abbrechen",
  HISTORY_DELETE_ALL_BUTTON: "Alle löschen",
  HISTORY_DELETE_ANY_BUTTON: "Auswahl löschen",
};
