<script lang="ts">
  import {Label} from "../../constants/label";
  import {isFocus, isSuggestOpen} from "../../store/SearchfieldStore";
  import {SqrlTestId} from "../../constants/squirrel";


  /**
 *
 *
 */
  function handleAbort(event: Event) {
    event.preventDefault();
    if (event instanceof KeyboardEvent && event.key !== "Enter") {
      return;
    }
    $isFocus = false;
    $isSuggestOpen = false;
  }
</script>

{#if $isFocus}
  <span class="squirrel_searchline__abort js_squirrel_searchline__abort"
        data-testid={SqrlTestId.ABORT_BUTTON}
        role="button"
        on:click={handleAbort}
        on:keydown={handleAbort}>{Label.ABORT_BUTTON}</span>
{/if}

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/breakpoint" as breakpoint;
  @use "~@otto-ec/global-frontend-settings/scss/font-size" as fonts;
  @use "~@otto-ec/global-frontend-settings/scss/variables" as variables;

  .squirrel_searchline__abort {
    @include fonts.font-size(variables.$font100, false);

    display: block;
    padding-left: 8px;
    line-height: 40px;
    color: #008ffd;
    cursor: pointer;

    @include breakpoint.from-l($to: false) {
      display: none;
    }
  }
</style>
