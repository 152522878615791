import { TrackingLabel } from "../TrackingLabel";

export class SuggestAdoptPosition extends TrackingLabel {
  readonly name: string = "san_SuggestAdoptPosition";
  private _value: number = -1;

  get value(): string[] {
    return [this._value + ""];
  }

  set value(value: number) {
    this._value = value;
  }

  isTrackable(): boolean {
    return true;
  }
}

export const tlSuggestAdoptPosition = new SuggestAdoptPosition();
