<script lang="ts">
  import HistoryListItem from "./HistoryListItem.svelte";
  import HistoryListItemDelete from "./HistoryListItemDelete.svelte";
  import {visualSearches} from "../../../store/HistoryStore";
  import {SqrlTestId} from "../../../constants/squirrel";
  import {timeSuggestRender} from "../../../times/TimeFactory";

  timeSuggestRender.measure();
</script>

<ul class="squirrel_searchSuggestions__list" data-testid={SqrlTestId.HISTORY_LIST}>
    {#each $visualSearches as search, index}
        <HistoryListItem {search} {index}/>
    {/each}
    <HistoryListItemDelete></HistoryListItemDelete>
</ul>

<style lang="scss">
</style>
