<script lang="ts">
  import type {SqrlListItemDetails, SqrlListItemModel} from "../../../types/squirrel";
  import {createEventDispatcher} from "svelte";
  import {SqrlQaId} from "../../../constants/squirrel";
  import {activeIndex} from "../../../store/IndexStore";
  import SqrlListItemIcon from "./SqrlListIcon.svelte";

  export let params: SqrlListItemModel;

  const dispatch = createEventDispatcher();

  function handleClick(type: string) {
    dispatch(type, {...params.suggestion, index: params.index} as SqrlListItemDetails)
  }
</script>

<li class="squirrel_listItem squirrel_{params.name}"
    data-qa-id={SqrlQaId.SUGGEST_ITEM}
    data-testid="{params.name}-{params.index}">
    <div class="pl_table-view--item-fully-clickable">
        <div class="squirrel_listItem__row js_squirrel_{params.name} squirrel_{params.name}__text"
             data-qa-id={SqrlQaId.SUGGEST_ROW}>
            <SqrlListItemIcon params={params.leftIcon} on:click={() => handleClick("clickLeftIcon")}/>
            <div class="pl_copy100 squirrel_listItem__term js_squirrel_{params.name}__term squirrel_{params.name}__term"
                 class:squirrel_active={params.index === $activeIndex}
                 data-pos={params.index}
                 data-suggestpos={params.index + 1}
                 data-target={params.suggestion.target}
                 data-term={params.suggestion.term}
                 on:click|preventDefault={() => handleClick("clickItem")}
                 on:keydown|preventDefault
                 role="button"
                 tabindex="-1">{@html params.suggestion.visual}</div>
            <SqrlListItemIcon params={params.rightIcon} on:click={() => handleClick("clickRightIcon")}/>
        </div>
    </div>
</li>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;
  @use "~@otto-ec/global-frontend-settings/scss/font-size" as fonts;
  @use "~@otto-ec/global-frontend-settings/scss/variables" as variables;

  .squirrel_active {
    color: pl_variables.$pl_color-red100;
  }

  .squirrel_listItem {
    position: relative;
    cursor: pointer;
    background-color: white;

    &:hover {
      background-color: pl_variables.$pl_color-grey25;
    }

    &:not(:last-child):after {
      display: block;
      content: "";
      margin: 0 16px;
      border-bottom: 1px solid pl_variables.$pl_color-grey25;
    }
  }


  .squirrel_listItem__row {
    @include fonts.font-size(variables.$font100, false);

    display: flex;
    height: 48px;
    flex-direction: row;
    width: 100%;

    & > :first-child {
      padding-left: 16px;
    }

    & > :last-child {
      padding-right: 16px;
    }
  }

  .squirrel_listItem__term {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: max-content;
    flex: 1;
    padding: 12px 0;
  }
</style>