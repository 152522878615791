import type { SuggestResponse } from "../generated/models/SuggestResponse";
import type { SqrlPromise, SqrlQueuedPromise, SqrlReject, SqrlResolve } from "../types/squirrel";
import type { SuggestionRequest } from "../types/global";
import { IndexControllerService } from "../generated/services/IndexControllerService";
import { util } from "./util";
import { searchfieldStore } from "../store/SearchfieldStore";
import { suggestionStore } from "../store/SuggestionStore";
import { tlSuggestMethodAction } from "../tracking/labels/SuggestMethodAction";
import { tracking } from "../tracking/Tracking";

export class SqrlRequest {
  private queue: SqrlQueuedPromise[] = [];
  private pendingPromise = false;

  enqueue<T = void>(promise: SqrlPromise<T>): Promise<T> {
    return new Promise((resolve: SqrlResolve<T>, reject: SqrlReject<T>) => {
      this.queue.push({
        promise,
        resolve,
        reject,
      });
      this.dequeue();
    });
  }

  dequeue(): boolean {
    if (this.pendingPromise) {
      return false;
    }
    const item = this.queue.shift();
    if (!item) {
      return false;
    }
    try {
      this.pendingPromise = true;
      item
        .promise()
        .then((value) => {
          this.pendingPromise = false;
          item.resolve(value);
          this.dequeue();
        })
        .catch((err) => {
          this.pendingPromise = false;
          item.reject(err);
          this.dequeue();
        });
    } catch (err) {
      this.pendingPromise = false;
      item.reject(err);
      this.dequeue();
    }
    return true;
  }

  fetch(query: string, cursorPosition: number, encodedHistory?: string, initial: boolean = true) {
    const promise: SuggestionRequest = IndexControllerService.query(
      query,
      encodedHistory,
      cursorPosition,
    );
    this.enqueue(() => promise).then((response: SuggestionRequest) => {
      this.resolve(response, initial);
    });
  }

  resolve(response: SuggestResponse, initial: boolean) {
    if (util.string.compareIgnoreCase(response?.original.term, searchfieldStore.$value)) {
      searchfieldStore.$target = response?.original.target;
      suggestionStore.$keywords = response?.keywords || [];
      suggestionStore.$original = response?.original || [];

      const trackingData = tlSuggestMethodAction.data;
      if (Object.keys(trackingData).length && !initial) {
        tracking.trackSubmitEvent(trackingData);
      }
    }
  }
}

export const sqrlRequest = new SqrlRequest();
