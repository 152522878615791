<script lang="ts">
  import {Label} from "../../constants/label";

  import {historyViewMode} from "../../store/HistoryStore";
  import {indexStore} from "../../store/IndexStore";
  import {SqrlTestId} from "../../constants/squirrel";

  let label: Label = Label.HISTORY_EDIT_BUTTON;

  $: label = $historyViewMode ? Label.HISTORY_EDIT_BUTTON : Label.HISTORY_ABORT_BUTTON;

  function toggleEditMode(event: Event) {
    if (event instanceof KeyboardEvent && event.key !== "Enter") {
      return;
    }
    indexStore.reset()
    historyViewMode.toggle();
  }
</script>

<span class="pl_link100--primary squirrel_history_edit_button js_squirrel_history_edit_button js_squirrel_ignore_suggest"
      role="button"
      data-testid={SqrlTestId.HISTORY_EDIT_BUTTON}
      on:click={toggleEditMode}
      on:keydown={toggleEditMode}>{label}</span
>

<style lang="scss">
  .squirrel_history_edit_button {
    flex: 0 0 50%;
    padding-left: 8px;
    text-align: right;
  }
</style>
