<script lang="ts">
  import {Label} from "../../../constants/label";
  import TrashIcon from "../../interactive/TrashIcon.svelte";
  import {someSelected, submitHistory} from "../../../store/HistoryStore";
  import {tracking} from "../../../tracking/Tracking";
  import {tlSearchHistoryDeletedTerms} from "../../../tracking/labels/SearchHistoryDeletedTerms";
  import {tlSearchHistoryDelete} from "../../../tracking/labels/SearchHistoryDelete";
  import {tlSearchHistoryDeleteMode} from "../../../tracking/labels/SearchHistoryDeleteMode";

  function deleteHistory() {
    tracking.trackSubmitEvent({
      ...tlSearchHistoryDeletedTerms.data,
      ...tlSearchHistoryDelete.data,
      ...tlSearchHistoryDeleteMode.data,
    });
    submitHistory.deleteHistory();
  }
</script>

<li class="squirrel_searchHistoryDelete js_squirrel_ignore_suggest"
    on:click={deleteHistory}
    role="button"
    on:keypress={(event) => {
          if (event instanceof KeyboardEvent && event.key === "Enter") {
            submitHistory.deleteHistory();
          }
        }}>
    <div class="pl_table-view--item-fully-clickable js_squirrel_ignore_suggest">
        <div
            data-qa-id="search-history-delete"
            class="squirrel_searchHistoryDelete__text js_squirrel_searchHistoryDelete js_squirrel_ignore_suggest pl_link100--primary"
        >
            <TrashIcon/>
            <span
                class="pl_copy100 squirrel_searchHistoryDelete__term js_squirrel_searchHistory__term js_squirrel_ignore_suggest"
            >{$someSelected ? Label.HISTORY_DELETE_ANY_BUTTON : Label.HISTORY_DELETE_ALL_BUTTON}</span
            >
        </div>
    </div>
</li>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;
  @use "~@otto-ec/global-frontend-settings/scss/font-size" as fonts;
  @use "~@otto-ec/global-frontend-settings/scss/variables" as variables;

  .squirrel_searchHistoryDelete {
    position: relative;
    cursor: pointer;
    background-color: white;
    padding: 0 16px;

    &:not(:last-child) {
      > div {
        border-bottom: 1px solid pl_variables.$pl_color-grey25;
      }
    }

    &__text {
      @include fonts.font-size(variables.$font100, false);

      padding: 12px 0;
      display: flex;
      height: 48px;

      > .squirrel_searchHistoryDelete__term {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 40px;
      }
    }
  }
</style>
