import { derived, type Readable } from "svelte/store";
import { searchfieldStore, value } from "./SearchfieldStore";
import * as historyStore from "../store/HistoryStore";
import { SuggestType } from "../constants/suggestType";
import { indexStore } from "./IndexStore";
import { original } from "./SuggestionStore";
import { util } from "../util/util";

let currentSuggestType: SuggestType;

export const suggestType: Readable<SuggestType> = derived(
  [
    searchfieldStore.isEmpty,
    searchfieldStore.isSuggestOpen,
    historyStore.isEmpty,
    indexStore.activeIndex,
    indexStore.isSuggestTypeFrozen,
  ],
  ([
    $searchFieldIsEmpty,
    $searchFieldIsSuggestOpen,
    $historyIsEmpty,
    $activeIndex,
    $isSuggestTypeFreezed,
  ]) => {
    if (!$searchFieldIsSuggestOpen) {
      currentSuggestType = SuggestType.CLOSED;
    } else if ($isSuggestTypeFreezed) {
      return currentSuggestType;
    } else if ($activeIndex !== -1 || $isSuggestTypeFreezed) {
      return currentSuggestType;
    } else if (!$searchFieldIsEmpty) {
      indexStore.reset();
      currentSuggestType = SuggestType.SUGGEST;
    } else if ($searchFieldIsEmpty && !$historyIsEmpty) {
      indexStore.reset();
      currentSuggestType = SuggestType.HISTORY;
    } else if ($historyIsEmpty) {
      indexStore.reset();
      currentSuggestType = SuggestType.TRENDING_QUERY;
    } else {
      currentSuggestType = SuggestType.CLOSED;
    }
    return currentSuggestType;
  },
);

export const allowSubmit = derived([value, original], ([$value, $original]) => {
  return util.string.compareIgnoreCase($value, $original.term);
});
