<svg
  class="pl_icon js_squirrel_ignore_suggest"
  role="img"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  xmlns="http:/*                  */
>
  <use xlink:href="/assets-static/icons/pl_icon_delete.svg#pl_icon_delete"></use>
</svg>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;

  svg {
    flex: 0 0 24px;
    height: 24px;
    margin-right: 16px;
    fill: pl_variables.$pl_color-blue100;
  }
</style>
