<script lang="ts">
  import type {Optional} from "../../../types/squirrel";
  import type {SqrlListIconModel} from "../../../types/squirrel";
  import {createEventDispatcher} from "svelte";
  import {SqrlTestId} from "../../../constants/squirrel";

  export let params: Optional<SqrlListIconModel>;

  const dispatch = createEventDispatcher();

  function handleClick() {
    dispatch("click", params);
  }

</script>
{#if params !== undefined && params.iconTarget}
    <div class="squirrel_icon pl_copy100 {params?.classNames?.join(' ') || ''}"
         data-qa-id={params.qaId}
         data-testid={params.testId || SqrlTestId.LIST_ICON}
         on:click|preventDefault={handleClick}
         on:keydown|preventDefault={handleClick}
         role="button"
         tabindex="-1">
        <svg class="pl_icon {params.style?.color || ''}" role="img">
            <use xlink:href={params.iconTarget}></use>
        </svg>
    </div>
{/if}

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;

  .squirrel_icon {
    display: flex;
    color: #000;
    cursor: pointer;
    width: min-content;
    height: max-content;
    padding: 12px 16px;

    svg {
      height: 24px;
      width: 24px;

      &.red {
        fill: pl_variables.$pl_color-red100;
      }
    }
  }
</style>