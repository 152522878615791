<script lang="ts">
  import {FindQa, SqrlQaId, SqrlTestId} from "../../constants/squirrel";
  import {searchfieldStore} from "../../store/SearchfieldStore";
  import {KEY_CODE} from "../../constants/keyCode";
  import {eventQBus} from "@otto-ec/event-q-bus";
  import {timeInitSearchField, timeSuggest, timeSuggestRender} from "../../times/TimeFactory";
  import {activeIndex, indexStore, isSuggestTypeFrozen} from "../../store/IndexStore.js";
  import {keywords} from "../../store/SuggestionStore";
  import {suggestType} from "../../store/Derived";
  import {SuggestType} from "../../constants/suggestType";
  import {sendTrackingLabelOnFocus} from "../../store/TrackingStore";
  import {tlInitialSearchBoxTime} from "../../tracking/labels/InitialSearchBoxTime";
  import {createEventDispatcher, onDestroy} from "svelte"
  import {Label} from "../../constants/label";
  import {SQRL_EVENT} from "../../events/sqrlEvents";
  import {submitStore} from "../../store/SubmitStore";

  const bus = eventQBus<never>();
  let inputElement: HTMLInputElement;
  let {value, target, isFocus, isEmpty, isSuggestOpen} = searchfieldStore;
  let dispatch = createEventDispatcher();
  let maxlength = 150;

  /**
 *
 */
  const unsubscribe = isEmpty.subscribe((value: boolean) => {
    if (value) {
      timeSuggest.reset()
    }
  })

  function getCursorPosition() {
    const selectionStart = inputElement.selectionStart;
    const termLength: number = $value.length;
    return (selectionStart && selectionStart <= termLength) ? selectionStart : termLength;
  }

  /**
 *
 *
 *
 *
 *
 */
  function handleInput() {
    dispatch("sendRequest", {term: $value, cursorPosition: getCursorPosition()})
    if (!$value) {
      indexStore.reset()
    }
    $sendTrackingLabelOnFocus = false;
    $isSuggestOpen = true;
  }

  /**
 *
 *
 *
 *
 */
  function handleFocus() {
    timeSuggestRender.mark();
    if (!$value) {
      dispatch("sendRequest", {term: "", cursorPosition: 0})
    }
    if (!$isFocus) {
      $isFocus = true;
    }
    if (!$isSuggestOpen) {
      $isSuggestOpen = $isEmpty;
      timeInitSearchField.measureOnce(tlInitialSearchBoxTime.sessionStart!)
    }
  }

  function handleBlur() {
    $isSuggestOpen = false;
    $isFocus = false;
    inputElement?.blur();
    submitStore.$breadcrumbSearch = false;
  }

  bus.on(SQRL_EVENT.SUGGEST_CLOSE, () => {
    handleBlur();
  });

  bus.on(SQRL_EVENT.SUGGEST_OPEN, () => {
    handleFocus();
  });


  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  function handleKeydown(event: KeyboardEvent) {
    timeSuggestRender.mark();

    function updateValue() {
      if ($activeIndex !== -1) {
        $value = $keywords[$activeIndex].term;
      }
    }

    if (event.code === KEY_CODE.ESC || event.key === KEY_CODE.ESC) {
      handleBlur();
    }

    if ($suggestType === SuggestType.TRENDING_QUERY || $suggestType === SuggestType.SUGGEST) {
      if (event.code === KEY_CODE.ARROW_UP) {
        event.preventDefault();
        indexStore.previous();
        updateValue();
        return;
      } else if (event.code === KEY_CODE.ARROW_DOWN) {
        event.preventDefault();
        indexStore.next();
        updateValue();
        return;
      } else if ([KEY_CODE.ARROW_LEFT, KEY_CODE.ARROW_RIGHT, KEY_CODE.TAB, KEY_CODE.BACKSPACE, KEY_CODE.ENTER].includes(event.code as KEY_CODE)) {
        return;
      } else if (event?.key?.length !== 1 && $isSuggestTypeFrozen) {
        event.preventDefault();
        return;
      }
    }
    indexStore.reset();
  }

  /**
 *
 */
  function handleFocusout() {
    indexStore.reset();
  }

  onDestroy(unsubscribe);

</script>

<input
    class="squirrel_searchfield js_squirrel_searchbar__input"
    data-qa={FindQa.SEARCH_FIELD}
    data-qa-id={SqrlQaId.SEARCH_FIELD}
    data-testid={SqrlTestId.SEARCH_FIELD}
    data-target={$target}
    autocomplete="off"
    autocorrect="off"
    type="text"
    {maxlength}
    placeholder={Label.PLACEHOLDER}
    bind:this={inputElement}
    bind:value={$value}
    on:input={handleInput}
    on:focusin={handleFocus}
    on:focusout={handleFocusout}
    on:keydown={handleKeydown}
    role="searchbox"
    tabindex="0"
/>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/font-size" as fonts;
  @use "~@otto-ec/global-frontend-settings/scss/variables" as variables;
  @use "~@otto-ec/global-frontend-settings/scss/breakpoint" as breakpoint;

  .squirrel_searchfield {
    @include fonts.font-size(variables.$font125, false);

    color: variables.$black100;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0 0 0 16px;
    height: 40px;
    width: inherit;
    order: 1;

    @include breakpoint.from-l($to: false) {
      @include fonts.font-size(variables.$font100, false);
    }

    /*                                          */
    &::-ms-clear {
      display: none;
    }
  }
</style>
