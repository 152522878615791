<script lang="ts">
  import {submitHistory} from "../../store/HistoryStore";
  import type {HistoryItem} from "../../types/global";

  export let search: HistoryItem;
  export let index: number;
</script>

<input
        class="pl_checkbox__element squirrel_select_history_item js_squirrel_select_history_item js_squirrel_ignore_suggest"
        id="checkbox-default-unchecked"
        data-testid="squirrel-check-{search.term}"
        checked={search.selected}
        type="checkbox"
        on:click={() => submitHistory.select(index)}
/>

<style lang="scss">
  .squirrel_select_history_item {
    width: 56px;
    height: 56px;
    padding: 16px
  }
</style>
