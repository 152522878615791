<script lang="ts">
  import type {HistoryItem} from "../../../types/global";
  import HistoryCheckbox from "../../interactive/HistoryCheckbox.svelte";
  import {SqrlQaId} from "../../../constants/squirrel";
  import {historyViewMode} from "../../../store/HistoryStore";
  import {SUBMIT_TYPE, submitStore} from "../../../store/SubmitStore";
  import {SqrlUrl} from "../../../util/SqrlUrl";
  import {tlSearchType} from "../../../tracking/labels/SearchType";
  import {SearchHistoryValue, TL_HIGGINS} from "../../../tracking/labels/higginsLabel";
  import {SEARCH_TYPE} from "../../../constants/searchType";
  import type {TrackingData} from "../../../types/tracking";

  export let search: HistoryItem;
  export let index: number;

  /**
 *
 *
 *
 *
 *
 */
  function handleSubmit() {
    const trackingData: TrackingData = {
      [tlSearchType.name]: SEARCH_TYPE.HISTORY,
      [TL_HIGGINS.ACTIVE_SEARCH]: ["true"],
      [TL_HIGGINS.INITIAL_SEARCH]: ["true"],
      [TL_HIGGINS.INTERACTION]: ["initial_search"],
      [TL_HIGGINS.SEARCH_HISTORY]: [SearchHistoryValue.EXISTS],
      [TL_HIGGINS.SUGGEST_POS]: [`${index + 1}`],
    }
    const fallbackTarget: SqrlUrl = search.target ? new SqrlUrl(`${window.location.origin}${search.target}`) : new SqrlUrl(`${window.location.origin}/suche/${search.term}/`)

    submitStore.handleSubmit(search, fallbackTarget.urlWithRef(trackingData), SUBMIT_TYPE.HISTORY_ITEM)
  }
</script>

<li class="squirrel_searchHistory" data-qa-id={SqrlQaId.HISTORY_ITEM} role="listitem">
    <div class="squirrel_searchHistory__text js_squirrel_searchHistory"
         data-qa-id={SqrlQaId.HISTORY_ITEM_ROW}>
        {#if $historyViewMode}
            <oc-icon-v1 type="duration" size="100" on:click|preventDefault={handleSubmit}></oc-icon-v1>
        {:else}
            <HistoryCheckbox {search} {index}/>
        {/if}

        <div data-term={search.term}
              on:click|preventDefault={handleSubmit}
              data-pos={index}
              class="squirrel_searchHistory__term
                 js_squirrel_searchHistory__term
                 js_squirrel_ignore_close_suggest">
            <div class="pl_copy100">{search.term}</div>{#if search.tags && search.tags.length > 0}
                <div class="pl_copy75">
                    {search.tags.join(", ")}
                </div>
            {/if}
        </div>
        <oc-icon-v1 type="arrow-right" size="100" color="#f00020" on:click|preventDefault={handleSubmit}></oc-icon-v1>
    </div>
</li>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;
  @use "~@otto-ec/global-frontend-settings/scss/font-size" as fonts;
  @use "~@otto-ec/global-frontend-settings/scss/variables" as variables;

  oc-icon-v1{
    padding: 16px;
    width: 56px;
    height: 56px;
  }

  .squirrel_searchHistory {
    position: relative;
    cursor: pointer;
    background-color: white;

    &:not(:last-child) {
      > div {
        border-bottom: 1px solid pl_variables.$pl_color-grey25;
      }
    }



    &__text {
      @include fonts.font-size(variables.$font100, false);

      display: flex;
      height: 56px;

      justify-content: center;
      align-items: center;


      .squirrel_searchHistory__term {
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }


      }
    }

    /*           */
    /*                   */
    //}

    &:hover {
      background-color: pl_variables.$pl_color-grey25;
    }
  }
</style>
