<script lang="ts">
  import SubmitButton from "../interactive/SubmitButton.svelte";
  import SearchResetButton from "../interactive/SearchResetButton.svelte";
  import AbortButton from "../interactive/AbortButton.svelte";
  import SearchField from "../interactive/SearchField.svelte";
  import {searchfieldStore} from "../../store/SearchfieldStore";
  import {activeIndex} from "../../store/IndexStore";
  import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
  import {SqrlTestId} from "../../constants/squirrel";
  import {allowSubmit} from "../../store/Derived";
  import type {Unsubscriber} from "svelte/store";

  const {value, target} = searchfieldStore;
  let unsubscribe: Unsubscriber;


  /**
 *
 *
 *
 *
 *
 *
 *
 */

  function handleSubmit() {
    if ($value.trim() === "") {
      return;
    }
    if ($activeIndex > -1) {
      const suggestItem: HTMLElement = document.querySelector(`[data-pos="${$activeIndex}"]`) as HTMLElement;
      suggestItem.click();
    } else {
      unsubscribe = allowSubmit.subscribe((allowSubmit) => {
        if (allowSubmit) {
          submitStore.handleSubmit({term:$value}, $target, SUBMIT_TYPE.ENTER);
        }
        /*                     */
        unsubscribe && unsubscribe();
      });
    }
  }
</script>

<form class="js_squirrel_searchForm squirrel_searchForm"
      data-article-number-search="/p/search/"
      data-testId={SqrlTestId.SEARCH_LAYER}
      on:submit|preventDefault={handleSubmit}>
    <div class="squirrel_searchline">
        <div class="squirrel_searchbar js_squirrel_searchbar">
            <SearchField on:sendRequest/>
            <SearchResetButton/>
            <SubmitButton/>
        </div>
        <AbortButton/>
    </div>
</form>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;

  .squirrel_searchline {
    display: flex;
  }

  .squirrel_searchbar {
    display: flex;
    background-color: pl_variables.$pl_color-grey25;
    border-radius: 20px;
    width: 100%;
  }
</style>
