import "./styles/global.scss";
import Stomachshop from "./Stomachshop.svelte";
import { OpenAPI } from "./generated/core/OpenAPI";

/*                                      */
OpenAPI.BASE = window.location.origin;

new Stomachshop({
  hydrate: true,
  target: document.getElementById("js_squirrel_stomachshop") as HTMLElement,
});
