<script lang="ts">
  import {FindQaId, SqrlQaId, SqrlTestId} from "../../constants/squirrel";
  import {searchfieldStore} from "../../store/SearchfieldStore";
  import {activeIndex, indexStore} from "../../store/IndexStore";
  import {tracking} from "../../tracking/Tracking";
  import {SanHeader} from "../../events/sanHeader";

  const {value, isEmpty} = searchfieldStore;

  /**
 *
 *
 *
 *
 *
 *
 */
  function handleReset(event: Event) {
    event.preventDefault();
    if (event instanceof KeyboardEvent && event.key !== "Enter") {
      return;
    }
    $activeIndex = -1;
    indexStore.reset();
    $value = "";
    const inputElement: HTMLInputElement | null = document.querySelector(".js_squirrel_searchbar__input");
    inputElement?.focus();
    tracking.trackSubmitEvent({san_Header: [SanHeader.X]});
  }
</script>

{#if !$isEmpty}
    <div class="squirrel_delete-button js_squirrel_searchbar__delete"
         data-qa-id={SqrlQaId.SEARCH_RESET}
         data-testid={SqrlTestId.SEARCH_RESET}
         on:click={handleReset}
         on:keydown={handleReset}
         role="button">
        <svg class="pl_icon" data-qa={FindQaId.SEARCH_RESET_SVG} role="img">
            <use xlink:href="/assets-static/icons/pl_icon_close.svg#pl_icon_close"></use>
        </svg>
    </div>
{/if}

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;

  .squirrel_delete-button {
    cursor: pointer;
    order: 2;
    margin: 4px 4px 0 0;

    svg {
      width: 32px;
      height: 32px;
      padding: 6px;
      fill: pl_variables.$pl_color-grey400;

      &:hover {
        fill: pl_variables.$pl_color-red100;
      }
    }
  }
</style>
