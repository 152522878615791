import { getLengthOfHistoryItems, submitHistory } from "../../store/HistoryStore";
import { TrackingLabel } from "../TrackingLabel";

export enum SearchHistoryDeleteValue {
  ALL = "all",
  SOME = "some",
}

export class SearchHistoryDelete extends TrackingLabel<SearchHistoryDeleteValue[]> {
  get lengthOfToBeDeletedHistoryItems(): number {
    return submitHistory.getHistoryItemsToBeDeleted().length;
  }

  get name(): string {
    return "san_SearchHistoryDelete";
  }

  get value(): SearchHistoryDeleteValue[] {
    const lengthDeletedHistoryItems = this.lengthOfToBeDeletedHistoryItems;
    const lengthHistoryItems = getLengthOfHistoryItems();
    if (lengthDeletedHistoryItems === 0) {
      return [];
    } else if (lengthDeletedHistoryItems === lengthHistoryItems) {
      return [SearchHistoryDeleteValue.ALL];
    } else {
      return [SearchHistoryDeleteValue.SOME];
    }
  }

  isTrackable(): boolean {
    return this.value.length !== 0 && this.lengthOfToBeDeletedHistoryItems > 0;
  }
}

export const tlSearchHistoryDelete = new SearchHistoryDelete();
