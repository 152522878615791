<script lang="ts">
  import type {Suggestion} from "../../generated";
  import type {SqrlListIconModel, SqrlListItemModel} from "../../types/squirrel";
  import {onDestroy} from "svelte";
  import {Label} from "../../constants/label";
  import {SqrlTestId} from "../../constants/squirrel";
  import {keywords} from "../../store/SuggestionStore";
  import {timeEmptyState, timeSuggestRender} from "../../times/TimeFactory";
  import {indexStore} from "../../store/IndexStore";
  import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
  import SqrlListItem from "../interactive/listItem/SqrlListItem.svelte";

  timeEmptyState.mark()

  const rightIcon: SqrlListIconModel = {
    iconTarget: "/assets-static/icons/pl_icon_arrow-right.svg#pl_icon_arrow-right",
    style: {color: "red"},
    classNames: ["squirrel_arrow_right-icon"]
  };
  const leftIcon: SqrlListIconModel = {iconTarget: "/assets-static/icons/pl_icon_arrow-graph.svg#pl_icon_arrow-graph"};

  const unsubscribe = keywords.subscribe((keywords) => {
    indexStore.updateMaxIndex(keywords.length);
  })

  function handleClick(event: CustomEvent<Suggestion>) {
    timeEmptyState.measure();
    submitStore.handleSubmit({term: event.detail.term}, event.detail.target, SUBMIT_TYPE.TRENDING_QUERY_ITEM);
  }

  function createModel(trendingQuery: Suggestion, index: number): SqrlListItemModel {
    return {name: "trendingQuery", suggestion: trendingQuery, index, leftIcon, rightIcon};
  }

  timeSuggestRender.measure();
  onDestroy(() => {
    timeEmptyState.measure()
    unsubscribe();
  })
</script>

<div class="squirrel_trendingQueries pl_table-view" data-testid={SqrlTestId.TRENDING_QUERY_LIST}>
    <div class="pl_table-view--headline squirrel_searchSuggestions__headline">
        <span class="pl_copy75">{Label.TRENDING_QUERY_HEADER}</span>
    </div>
    <ul class="squirrel_trendingQueries__list squirrel_border_top">
        {#each $keywords as trendingQuery, index}
            <SqrlListItem params={createModel(trendingQuery, index)}
                          on:clickItem={handleClick}
                          on:clickLeftIcon={handleClick}
                          on:clickRightIcon={handleClick}
            />
        {/each}
    </ul>
</div>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;

  .squirrel_trendingQueries {
    background-color: pl_variables.$pl_color-white100;
    padding: 0;
    border-radius: 0 0 8px 8px;
  }

  .squirrel_searchSuggestions__headline {
    padding: 24px 16px 8px;
    height: auto;
    color: pl_variables.$pl_color-grey400;
  }
</style>
