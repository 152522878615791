import { historyViewMode, submitHistory } from "../../store/HistoryStore";
import { get } from "svelte/store";
import { TrackingLabel } from "../TrackingLabel";

export enum SearchHistoryDeleteModeValue {
  NORMAL = "normal",
  EDIT = "edit",
}

export class SearchHistoryDeleteMode extends TrackingLabel<SearchHistoryDeleteModeValue[]> {
  readonly name: string = "san_SearchHistoryDeleteMode";

  get lengthOfToBeDeletedHistoryItems(): number {
    return submitHistory.getHistoryItemsToBeDeleted().length;
  }

  get value(): SearchHistoryDeleteModeValue[] {
    if (!get(historyViewMode)) {
      return [SearchHistoryDeleteModeValue.EDIT];
    }
    return [SearchHistoryDeleteModeValue.NORMAL];
  }

  isTrackable(): boolean {
    return this.value.length !== 0 && this.lengthOfToBeDeletedHistoryItems > 0;
  }
}

export const tlSearchHistoryDeleteMode = new SearchHistoryDeleteMode();
