<script lang="ts">
  import HistoryList from "./HistoryList.svelte";
  import {Label} from "../../../constants/label";
  import HistoryEditButton from "../../interactive/HistoryEditButton.svelte";
  import {onDestroy} from "svelte";
  import {historyViewMode, submitHistory} from "../../../store/HistoryStore";

  onDestroy(() => {
    historyViewMode.set(true);
    submitHistory.resetSelected();
  });
</script>

<span class="js_squirrel_history_layer">
  <div class="pl_table-view--headline squirrel_searchSuggestions__headline squirrel_border_top">
    <span class="pl_copy75">{Label.HISTORY_HEADER}</span>
    <HistoryEditButton/>
  </div>
  <HistoryList/>
</span>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;

  .squirrel_searchSuggestions__headline {
    background-color: pl_variables.$pl_color-white100;
    padding: 24px 16px 8px;
    height: auto;
    color: pl_variables.$pl_color-grey400;
    display: flex;
    align-items: center;

    > span {
      flex: 0 0 50%;
      padding-right: 8px;
    }
  }
</style>
