import { TrackingLabel } from "../TrackingLabel";

export class SuggestAdoptTerm extends TrackingLabel {
  readonly name: string = "san_SuggestAdoptTerm";
  private _value: string = "";

  get value(): string[] {
    return [this._value];
  }

  set value(value: string) {
    this._value = value;
  }

  isTrackable(): boolean {
    return true;
  }
}

export const tlSuggestAdoptTerm = new SuggestAdoptTerm();
