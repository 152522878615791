<script lang="ts">
  import {FindQaId, SqrlQaId, SqrlTestId} from "../../constants/squirrel";
  import {searchfieldStore} from "../../store/SearchfieldStore";
  import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
  import {allowSubmit} from "../../store/Derived";
  import {onDestroy} from "svelte";
  import type {Unsubscriber} from "svelte/store";

  const {value, target, isEmpty} = searchfieldStore;
  let unsubscribe: Unsubscriber;

  /**
 *
 *
 *
 *
 *
 */

  function handleSubmit(event: Event) {
    event.preventDefault();
    if ($isEmpty || (event instanceof KeyboardEvent && event.key !== "Enter")) {
      return;
    }
    unsubscribe = allowSubmit.subscribe((allowSubmit) => {
      if (allowSubmit) {
        submitStore.handleSubmit({term:$value}, $target, SUBMIT_TYPE.SUBMIT_BUTTON);
      }
    });
  }

  onDestroy(() => {
    if (unsubscribe != undefined) {
      unsubscribe();
    }
  });
</script>

<div
        class="squirrel_submit-button js_squirrel_searchbar__submit"
        data-testid={SqrlTestId.SUBMIT_BUTTON}
        data-qa-id={SqrlQaId.SUBMIT_BUTTON}
        data-qa={FindQaId.SUBMIT_BUTTON}
        on:click={handleSubmit}
        on:keydown={handleSubmit}
        role="button"
>
    <svg
            class="pl_icon"
            class:squirrel_active={!$isEmpty}
            data-testid={SqrlTestId.SUBMIT_BUTTON_SVG}
            role="img"
    >
        <use xlink:href="/assets-static/icons/pl_icon_search.svg#pl_icon_search"></use>
    </svg>
</div>

<style lang="scss">
  @use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl_variables;

  .squirrel_submit-button {
    cursor: pointer;
    order: 3;
    margin: 4px 4px 0 0;
    color: blue;

    svg {
      height: 32px;
      width: 32px;
      padding: 4px;
      fill: pl_variables.$pl_color-grey400;

      &.squirrel_active {
        background-color: pl_variables.$pl_color-red100;
        border-radius: 50%;
        fill: pl_variables.$pl_color-white100;
      }
    }
  }
</style>
